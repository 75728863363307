import { Identity } from '@schibsted/account-sdk-browser'
import { renderAutoLoginMessage } from './auto-login-message'
import { whenPrerenderingActivated } from './trackers/pulse'
import log from './log'

const hasSpidCookie = () => document.cookie.indexOf('SP_ID') > -1
const isProd = host => ['www.vg.no', 'spesial.vg.no', '2020.vg.no'].includes(host)

const createIdentityHelper = () => {
    const config = {
        prod: {
            clientId: '4ef1cfb0e962dd2e0d8d0000',
            server: 'payment.schibsted.no',
            sessionDomain: 'https://id.vg.no',
            redirectUri: 'https://www.vg.no/auth/callback',
            env: 'PRO_NO',
            productIds: ['100023', '301784', '301814', 'FEA-VG-PLUSARTICLES', '301895', '301901'],
        },
        dev: {
            clientId: '4f0d95c6efd04b7c44000005',
            redirectUri: window.location.origin,
            sessionDomain: 'https://id-pre.vg.no',
            env: 'PRE',
        },
    }

    const hadSpidCookie = hasSpidCookie()

    const identity = new Identity(isProd(window.location.host) ? config.prod : config.dev)
    identity.enableVarnishCookie()

    function setAuthState(url, utmMeidum = 'vg-special') {
        const state = Math.random().toString(16)
        const urlObj = new URL(url)
        urlObj.searchParams.set('utm_source', 'login')
        urlObj.searchParams.set('utm_medium', utmMeidum)

        window.sessionStorage.setItem(
            'auth_state',
            JSON.stringify({
                action: 'login',
                state,
                origin: urlObj.toString(),
            }),
        )

        return state
    }

    const getIdentifier = async () => {
        try {
            const context = await identity.getUserContextData()
            return context && context.identifier
        } catch (error) {
            log('Error getting user context data', error)
        }
    }

    if (location.search.indexOf('autologin') > -1) {
        renderAutoLoginMessage()
    }

    const autoLogin = async () => {
        await whenPrerenderingActivated()
        const lastAutoLogin = localStorage.getItem('vg-autologin')
        if (lastAutoLogin && parseInt(lastAutoLogin) > Date.now() - 1000 * 60 * 60 * 24 * 3) {
            return
        }
        if (await identity.isLoggedIn()) {
            return
        }
        const identifier = await getIdentifier()
        if (identifier) {
            localStorage.setItem('vg-autologin', Date.now())
            const url = new URL(window.location.href)
            identity.login({
                loginHint: identifier,
                state: setAuthState(url.toString(), 'autologin'),
            })
        }
    }

    function login(opts = {}) {
        const state = setAuthState(opts.url || window.location.href)
        return identity.login({ state })
    }

    function loginUrl(opts = {}) {
        const state = setAuthState(opts.url || window.location.href, opts.utmMedium)
        return identity.loginUrl({ state })
    }

    function logout(opts = {}) {
        const url = opts.url || window.location.href
        return identity.logout(url)
    }

    async function showSimplifiedLoginWidget(opts = {}) {
        if (await identity.isLoggedIn()) {
            return
        }

        const DAYS = 1000 * 60 * 60 * 24
        const expireAfter = DAYS * 1

        if (
            !localStorage.getItem('continue-as') ||
            parseInt(localStorage.getItem('continue-as')) < Date.now() - expireAfter
        ) {
            const state = setAuthState(opts.url || window.location.href)
            return identity.showSimplifiedLoginWidget({ state }).then(() => {
                localStorage.setItem('continue-as', Date.now())
            })
        }
    }

    async function getUser({ promo = false } = {}) {
        try {
            const user = await identity.hasSession()
            log('got user session', user, { hadSpidCookie })

            if (promo && !hadSpidCookie && hasSpidCookie()) {
                // Initial load after logging in may require a reload because of Varnish/spid cookie
                log('reloading for has-spid-cookie')
                window.location.reload(true)
            }

            if (user.clientAgreementAccepted && user.defaultAgreementAccepted) {
                return user
            }

            return null
        } catch (error) {
            log('not logged in', error)
            return null
        }
    }

    return {
        identity,
        login,
        loginUrl,
        logout,
        getUser,
        showSimplifiedLoginWidget,
        autoLogin,
    }
}

let identityHelper = null

const getIdentityHelper = () => {
    if (!identityHelper) {
        identityHelper = createIdentityHelper()
    }

    return identityHelper
}

export default getIdentityHelper
