import log from '../log'
import countWords from '../count-words'
import parseOpengraph from '../parse-opengraph'
import getIdentityHelper from '../identity-helper'
import removeUTMTags from '../remove-utm-tags'
import { loadConsentManagerIfNecessary } from '../consent-manager'

export async function loadPulseIfNecessary() {
    if (window.pulse) {
        return
    }

    log('pulse.load')

    if (!window.pulse) {
        window.pulse =
            window.pulse ||
            function () {
                ;(window.pulse.q = window.pulse.q || []).push(arguments)
            }

        import(/* webpackIgnore: true */ 'https://sdk.pulse.schibsted.com/versioned/3/pulse.min.js')
        import(
            /* webpackIgnore: true */ 'https://sdk.pulse.schibsted.com/plugins/activity-pings/plugin.js'
        )
        import(
            /* webpackIgnore: true */ 'https://sdk.pulse.schibsted.com/plugins/local-history/plugin.js'
        )
    }
}

const getUtmParams = url => {
    const searchParams = new URLSearchParams(url.search)
    const utmParams = {}
    for (const [key, value] of searchParams) {
        if (key.startsWith('utm_')) {
            if (key === 'utm_medium') {
                utmParams.channel = value
            } else {
                utmParams[key.replace('utm_', '')] = value
            }
        }
    }
    return utmParams
}

export const whenPrerenderingActivated = () =>
    new Promise(resolve => {
        if (document.prerendering) {
            document.addEventListener('prerenderingchange', () => resolve(), {
                once: true,
            })
        } else {
            resolve()
        }
    })

const STATE = {
    didInitPulse: false,
}

export default async (metadata, opts = {}) => {
    await whenPrerenderingActivated()
    log('pulse.track')

    loadPulseIfNecessary()
    loadConsentManagerIfNecessary()

    const og = parseOpengraph()

    const sdkConfig = {
        useBeacon: true,
        useBeaconWhenAvailable: true,
        altEventHandler: window.pulseEventHandler,
        nativeJwe: window.hermesJwe,
        autoCreateConsents: true,
        requireAdvertisingOptIn: true, // required when you must support opt-out from targeted advertising
    }

    const type = opts.promo ? 'SalesPoster' : 'Article'

    const evt = {
        object: {
            id: metadata.articleId,
            type,
            category: 'Spesial',
            contentId: metadata.articleId,
            authors: (Array.isArray(metadata.authors) && metadata.authors) || [],
            tags: (Array.isArray(metadata.tags) && metadata.tags) || [],
            custom: {
                'spt:published': metadata.published,
                'spt:updated': metadata.updated,
                'spt:authors': metadata.authors,
                'spt:permalink': metadata.url,
                'spt:wordCount': countWords(),
                'spt:group': metadata.group,
                'spt:imageUrl': og['og:image'],
                'spt:site': 'VG',
                'spt:shareUrl': {
                    facebook: metadata.url,
                    twitter: metadata.url,
                },
                'spt:previewUrl': {
                    http: metadata.url + '?forcedevice=mobile-smartphone',
                    https:
                        String(metadata.url).replace('http:', 'https:') +
                        '?forcedevice=mobile-smartphone',
                },
            },
        },
        provider: {
            productType: 'Web',
            product: 'vg',
            productTag: 'vg',
            'spt:engage': 'VG',
        },
    }

    if (type === 'Article') {
        evt.object.accessLevel = opts.paid ? 'Paid' : 'Free'
    }

    const utmParams = getUtmParams(window.location)

    evt.origin = {
        url: document.referrer,
        ...utmParams,
    }

    if (opts.originUrl) {
        evt.origin = { url: opts.originUrl }
    }

    log('pulse.event', evt)

    if (!STATE.didInitPulse) {
        pulse('init', 'vg', sdkConfig, evt)
        pulse('require', 'localHistory')

        STATE.didInitPulse = true
    }

    const { getUser } = getIdentityHelper()

    pulse('update', {
        actor: getUser({ promo: opts.promo })
            .then(user => {
                if (user) {
                    return {
                        id: user.userId,
                        realm: 'spid.no',
                    }
                }

                return {
                    id: undefined,
                }
            })
            .catch(err => {
                log(err)
                return {
                    id: undefined,
                }
            }),
    })

    const promise = new Promise((resolve, reject) =>
        pulse(sdk => {
            try {
                console.log('tracking initial page view')
                sdk.trackPageView().then(resolve).catch(reject)
            } catch (error) {
                reject(error)
            }
        }),
    )

    promise
        .then(() => {
            console.log('tracking initial page view done')
            log('removing utm tags')
            removeUTMTags()
        })
        .catch(err => {
            console.error('tracking initial page view failed', err)
        })

    pulse(`require`, 'engagementTime')

    if (opts.trackLeave || typeof opts.trackLeave === 'undefined') {
        pulse(sdk => {
            const objectElement =
                opts.trackLeave?.objectElement || document.querySelector('main') || document.body

            const pageElement = opts.trackLeave?.pageElement || document.body

            sdk.addPageLeaveTracking(objectElement, pageElement)
        })
    }

    return promise
}
